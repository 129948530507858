<template>
	<div class="page_wrap">
		<top :bgWhite='true' ></top>
		<div class="banner">
			<img src="../../assets/image/center/my_bg.png" >
		</div>
		<div class="w55 content-boxs  padding-tb-15  padding-lr-60">
			<!-- 作者信息 -->
			<div class="talents_info margin-top-20">
				<el-row :gutter="24" style="height: 100%;" class="flex align-end padding-lr padding-tb-20">
					<el-col :span="12">
						<div class="flex align-center writer-info">
							<div class="head-box">
								<el-image :src="userData.head_img" class="head-img margin-right-xs radius-90 block-90" fit="cover"></el-image>
								<img src="../../assets/image/icon/bigv.png" class="block-20 bigv" v-if="userData.is_v==1">
							</div>
							<!-- <img :src="userData.head_img" class="block-90 radius-48 margin-right-20"> -->
							<div class="left-info">
								<div class="flex align-center">
									<p class="name margin-right-5">{{userData.user_nickname ||'--'}}</p>
									<img src="../../assets/image/icon/madam.png" class="block-26" v-if="userData.sex==2">
									<img src="../../assets/image/icon/male.png" class="block-26" v-if="userData.sex==1">
									<!-- <p class="name margin-left-5 text-blue" v-if="userData.sex==0">保密</p> -->
								</div>
								<div class="writer-tag flex align-center margin-top-5" v-if="userData.tags_info">
									<p type="warning" v-if="j<2" size="mini" class="margin-right-5" v-for="(ele,j) in userData.tags_info" :key="j">{{ele}}</p>
								</div>
								<div class="flex align-end">
									<div class="text-cut-2">
										<span class="fs12 text-666 padding-right-100">{{userData.autograph ||'这个人很神秘，什么都没留下'}}</span>
									</div>
									<span class="text-blue point margin-left-5" style="flex-shrink: 0;" v-if="userData.is_writer==1" @click="toIntro">查看详情</span>
								</div>
							</div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="flex align-center justify-around">
							<div class="flex flex-direction align-center justify-center">
								<p class="fs16 bold">{{userData.focus || 0}}</p>
								<p class="fs9 margin-top-10 text-666">关注</p>
							</div>
							<div class="flex flex-direction align-center justify-center">
								<p class="fs16 bold">{{userData.fans || 0}}</p>
								<p class="fs9 margin-top-10 text-666">粉丝</p>
							</div>
							<div class="flex flex-direction align-center justify-center">
								<p class="fs16 bold">{{userData.like_num || 0}}</p>
								<p class="fs9 margin-top-10 text-666">点赞</p>
							</div>
							<div class="flex flex-direction align-center justify-center">
								<p class="fs16 bold">{{userData.footprint || 0}}</p>
								<p class="fs9 margin-top-10 text-666">浏览量</p>
							</div>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="flex flex-direction align-center justify-center">
							<div class="flex align-center">
								<el-button type="danger" v-if="userData.user_id!=userInfo.id" round="" size="mini" class="text-white" @click="addFocus">{{userData.is_focus==1 ? '已关注' : '关注'}}</el-button>
								<el-button type="warning" v-if="userData.user_id!=userInfo.id" round="" size="mini" class="text-white" @click="setBlack(userData.user_id)">拉黑</el-button>
							</div>
							<div class="margin-top-20 flex align-center">
								<span style="flex-shrink: 0;">坐标</span>
								<i class="el-icon-map-location fs20 margin-lr-10"></i>
								<span class="text-cut">{{userData.address_info ||'暂无'}}</span>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!-- 顶部tab  -->
			<div class="solid-bottom padding-tb-10 margin-tb-20 padding-lr-10">
				<el-row :gutter="24">
					<el-col :span="18">
						<div class="flex align-center padding-tb-4" >
							<div class="padding-lr-20" v-for="(item,index) in tabList" :key='index'>
								<span class="text-666 point fs18" @click="tabItem(item,index)" :class="tabIndex==index?'active_talent':''">{{item.name}}</span>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!-- 加载中 -->
			<div class="w100" v-if="!isInit">
				<el-skeleton :rows="12" animated />
			</div>
			<!-- 加载有数据 -->
			<div class=""v-else>
				<div v-if="lists.length>0">
					<!-- 动态 -->
					<div class="" v-if="tabIndex==0">
						<div  v-for="(item,index) in lists" :key="index">
							<dynamic  :is_remind='false' :current='tabIndex+1' :item="item" @addFocus="getUserDetail"></dynamic>
						</div>
					</div>
					<!-- 文章 -->
					<div class="" v-if=" tabIndex==1">
						<div  v-for="(item,index) in lists" :key="index">
							<dynamic  :is_remind='false' :current='tabIndex+1' :item="item" @addFocus="getUserDetail"></dynamic>
						</div>
					</div>
					<!--电子书 -->
					<el-row :gutter="24" v-if="tabIndex==2">
						<el-col :span="24" v-for="(item,index) in lists" :key="index">
							<div class="grid-content bg-purple">
								<book-simple :item="item"></book-simple>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="" v-if="lists.length==0">
					<el-empty description="~空空如也~"></el-empty>
				</div>
				<!-- 分页 -->
				<div class="flex align-center justify-center">
					<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='true' @current-change="currentChange">
					</el-pagination>
				</div>
				<!-- 分页 end-->
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import top from '@/components/top';
	import bottom from '@/components/bottom';
	import dynamic from '@/components/dynamic/dynamic';
	import bookSimple from '@/components/book-list/book-simple.vue';
	export default {
		components: {
			dynamic,
			bookSimple,
			bottom,
			top
		},
		props:{
			type: [Number, String]
		},
		computed: {
			userInfo() {
				return this.$store.getters.userInfo;
			}
		},
		data(){
			return{
				isInit:false, //是否加载完毕
				totalSize:'', //总页数
				user_id:'', //作者id
				userData:{}, //作者信息
				tabIndex:0,
				tabList: [{
					name: '动态',
					id: 1,
				}, {
					name: '文章',
					id: 2,
				}, {
					name: '电子书',
					id: 3,
				}],
				lists: []
			}
		},
		mounted() {
			this.user_id = this.$route.query.user_id
			this.getUserDetail();
			this.loadData()
		},
		methods:{
			// 拉黑
			setBlack(black_uid){
				this.$confirm('确认拉黑该用户？拉黑后，该用户将不能关注您并无法对您的作品进行评论', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  this.ajax('post', '/v1/61ee0aa299200', {
				  	black_uid: black_uid
				  }, res => {
				  	if (res.code == 1) {
				  		this.$message.success(res.msg);
				  	} else {
				  		this.$message.error(res.msg);
				  	}
				  })
				}).catch(() => {
				           
				});
				
			},
			// 加关注 关注方法
			addFocus(){
				this.ajax('post', '/v1/61419cb4727bd', {
					fuid: this.user_id
				}, res => {
					if (res.code == 1) {
						this.userData.is_focus = this.userData.is_focus ==1 ? 0 : 1;
						this.getUserDetail()
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			// 跳转作家简介
			toIntro(){
				this.$router.push({
					path:'/author_intro',
					query:{
						user_id:this.user_id
					}
				})
			},
			// tab切换
			tabItem(item, index) {
				if(this.tabIndex == index) return;
				this.tabIndex = index;
				this.isInit = false;
				this.lists = [];
				this.loadData()
			},
			// 获取作者信息
			getUserDetail() {
				this.ajax('post', '/v1/5c78c4772da97', {
					user_id:this.user_id
				}, res => {
					if (res.code == 1) {
						this.userData = res.data
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
				
			},
			// 跳页
			currentChange(e){
				console.log(e);
				this.page = e;
				this.loadData()
			},
			// 获取作家作品
			loadData() {
				this.ajax('post', '/v1/613b0f6c07cc9', {
					article_type:this.tabIndex+1, //1：动态2：文章3：电子书
					index_type:'' ,//1：关注2：推荐3：热榜
					page:this.page,
					user_id:this.user_id
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			}
		}
	}
</script>

<style  lang="scss" scoped>
.page_wrap{
	background: #F6F7F9 !important;
}
.text-blue{
	color: #098EF0;
}
.banner{
	img{
		width: 100%;
		height: 242px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
	}
}
.head-box{
	position: relative;
	.bigv{
		position: absolute;
		bottom: 5px;
		right: 5px;
	}
}
.writer-tag {
	p {
		width: 66px;
		height: 20px;
		text-align: center;
		opacity: 1;
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #FFFFFF;
		opacity: 1;
		&:nth-child(1){
			background: url(../../assets/image/icon/label-bg1.png) no-repeat;
			background-size: cover;
		}
		&:nth-child(2){
			background: url(../../assets/image/icon/label-bg2.png) no-repeat;
			background-size: cover;
		}
	}
}
.content-boxs{
	background: #FFFFFF;
	position: relative;
	top: -80px;
	.talents_info{
		width: 100%;
		height: 144px;
		border-radius: 8px;
		background: #F6F7F9;
	}
}
.left-info{
	.sex-img{
		width: 40px;
		height: 20px;
	}
}
.active_talent {
	position: relative;
	color: #333;
	font-weight: bold;
	font-size: 24px;
}

.active_talent::after {
	position: absolute;
	content: '';
	bottom: -15px;
	left: 0;
	right: 0;
	width: 40%;
	margin: auto;
	background-color: #E54F42;
	height: 4px;
	border-radius: 5px;
}
</style>